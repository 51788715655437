<template>
  <div>
    {{ userInfo.name }} 欢迎您登陆，现在时间是{{ date }}
  </div>
</template>

<script>
import { getUserInfo } from '@/utils/const';

export default {
  data() {
    return {
      userInfo: getUserInfo(),
      date: new Date().toLocaleTimeString(),
    };
  },
  mounted() {
    if (!getUserInfo().id) {
      this.$router.replace({
        name: 'login',
      });
    }
  },
};
</script>

<style>

</style>
